export default function CSVArrayParser(data) {
  var outputData = [];

  var dataDefinition = data.shift();

  for (let i = 0; i < data.length; i++) {
    const el = data[i];

    var dataEntry = {};
    for (let j = 0; j < el.length; j++) {
      const item = el[j];
      const itemDef = dataDefinition[j];
      dataEntry[itemDef] = item;
    }

    outputData.push(dataEntry);
  }

  //   console.log(outputData);

  return outputData;
}
