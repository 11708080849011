<template>
  <tr class="bordered">
    <td class="h-8">
      {{
        flightInfo.date != undefined
          ? dateDisplay(flightInfo.date.format("D"))
          : ""
      }}
    </td>
    <td>
      {{
        flightInfo.date != undefined
          ? dateDisplay(flightInfo.date.format("M"))
          : ""
      }}
    </td>
    <td>{{ flightInfo.depAirport || "" }}</td>
    <td>
      {{
        flightInfo.depTime != undefined ? timeDisplay(flightInfo.depTime) : ""
      }}
    </td>
    <td>{{ flightInfo.arrAirport || "" }}</td>
    <td>
      {{
        flightInfo.arrTime != undefined ? timeDisplay(flightInfo.arrTime) : ""
      }}
    </td>
    <td>{{ flightInfo.acModel || "" }}</td>
    <td>{{ flightInfo.acRegistration || "" }}</td>
    <td>{{ timeDisplay(flightInfo.SETime) || "" }}</td>
    <td>{{ timeDisplay(flightInfo.METime) || "" }}</td>
    <td>{{ timeDisplay(flightInfo.multiPilotTime) || "" }}</td>
    <td>{{ timeDisplay(flightInfo.totalFlightTime) || "" }}</td>
    <td>{{ flightInfo.namePIC || "" }}</td>
    <td>{{ flightInfo.landingsDay || "" }}</td>
    <td>{{ flightInfo.landingsNight || "" }}</td>
    <td>{{ timeDisplay(flightInfo.nightTime) || "" }}</td>
    <td>{{ timeDisplay(flightInfo.IFRTime) || "" }}</td>
    <td>{{ timeDisplay(flightInfo.PICTime) || "" }}</td>
    <td>{{ timeDisplay(flightInfo.CopiTime) || "" }}</td>
    <td>{{ timeDisplay(flightInfo.DualTime) || "" }}</td>
    <td>{{ timeDisplay(flightInfo.InstructorTime) || "" }}</td>
    <td>
      {{ flightInfo.Remarks || "" }}
    </td>
  </tr>
</template>

<script>
import { secondsToHM } from "../utils/timeCalculator";

export default {
  props: {
    flightInfo: {
      year: String,
      month: String,
      day: String,
      depAirport: String,
      depTime: String,
      arrAirport: String,
      arrTime: String,
      acModel: String,
      acRegistration: String,
      SETime: String,
      METime: String,
      multiPilotTime: String,
      totalFlightTime: String,
      namePIC: String,
      landingsDay: String,
      landingsNight: String,
      nightTime: String,
      IFRTime: String,
      PICTime: String,
      CopiTime: String,
      DualTime: String,
      InstructorTime: String,
      Remarks: String,
    },
  },
  mounted() {
    // console.log(this.flightInfo);
  },
  methods: {
    timeDisplay(d) {
      return d != 0 ? secondsToHM(d) : "";
    },
    dateDisplay(d) {
      return d < 10 ? "0" + d : d + "";
    },
  },
};
</script>

<style scoped>
tr.bordered {
  page-break-inside: avoid;
}

tr.bordered td,
tr.bordered th {
  padding: 3px;
  border: 1px solid black;
  page-break-inside: avoid;
  font-weight: normal;
}

tr.bordered td {
  font-size: smaller;
}
</style>
