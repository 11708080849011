<template>
  <div style="height: 95vh" class="flex flex-col justify-between">
    <main>
      <table
        class="pageTable left-0 top-0 right-0 bottom-0 m-auto overflow-x-scroll"
      >
        <thead>
          <tr class="bordered">
            <th class="headerSmall" colspan="2">1</th>
            <th class="headerSmall" colspan="2">2</th>
            <th class="headerSmall" colspan="2">3</th>
            <th class="headerSmall" colspan="2">4</th>
            <th class="headerSmall" colspan="3">5</th>
            <th class="headerSmall">6</th>
            <th class="headerSmall">7</th>
            <th class="headerSmall" colspan="2">8</th>
            <th class="headerSmall" colspan="2">9</th>
            <th class="headerSmall" colspan="4">10</th>
            <th class="headerSmall">12</th>
          </tr>
          <tr class="borderedBold">
            <th colspan="2" class="headerBig">Date</th>
            <th colspan="2" class="headerBig">Departure</th>
            <th colspan="2" class="headerBig">Arrival</th>
            <th colspan="2" class="headerBig">Aircraft</th>
            <th rowspan="1" colspan="2" class="headerBig">Single Pilot Time</th>
            <th rowspan="2" class="headerBig">Multi Pilot Time</th>
            <th rowspan="2" class="headerBig">Total Time of Flight</th>
            <th rowspan="2" class="headerBig">Name PIC</th>
            <th colspan="2" class="headerBig">Landings</th>
            <th colspan="2" class="headerBig">Operational Condition Time</th>
            <th colspan="4" class="headerBig">Pilot Function Time</th>
            <th rowspan="2" class="headerBig">Remarks and Endorsements</th>
          </tr>
          <tr class="borderedBold">
            <th class="headerSmall">dd</th>
            <th class="headerSmall">mm</th>
            <th class="headerSmall">Place</th>
            <th class="headerSmall">Time</th>
            <th class="headerSmall">Place</th>
            <th class="headerSmall">Time</th>
            <th class="headerSmall">Make, Model, Variant</th>
            <th class="headerSmall">Registration</th>
            <th class="headerSmall">SE</th>
            <th class="headerSmall">ME</th>
            <th class="headerSmall">Day</th>
            <th class="headerSmall">Night</th>
            <th class="headerSmall">Night</th>
            <th class="headerSmall">
              <div>IFR</div>
            </th>
            <th class="headerSmall">PIC</th>
            <th class="headerSmall">Copi</th>
            <th class="headerSmall">Dual Rcvd.</th>
            <th class="headerSmall">Instructor</th>
          </tr>
        </thead>

        <tbody>
          <logbook-row
            v-for="data in currentPageData"
            :key="data.Id"
            :flightInfo="data"
          ></logbook-row>

          <tr class="subtotal">
            <td class="subtotalLabel" colspan="6" rowspan="1"></td>
            <td colspan="2" rowspan="1">Totals This Page</td>

            <td>{{ timeDisplay(totals.totalsThisPage.SETime) }}</td>

            <td>{{ timeDisplay(totals.totalsThisPage.METime) }}</td>
            <td>{{ timeDisplay(totals.totalsThisPage.multiPilotTime) }}</td>
            <td>{{ timeDisplay(totals.totalsThisPage.totalFlightTime) }}</td>
            <td></td>
            <td>{{ totals.totalsThisPage.landingsDay }}</td>
            <td>{{ totals.totalsThisPage.landingsNight }}</td>
            <td>{{ timeDisplay(totals.totalsThisPage.nightTime) }}</td>
            <td>{{ timeDisplay(totals.totalsThisPage.IFRTime) }}</td>
            <td>{{ timeDisplay(totals.totalsThisPage.PICTime) }}</td>
            <td>{{ timeDisplay(totals.totalsThisPage.CopiTime) }}</td>
            <td>{{ timeDisplay(totals.totalsThisPage.DualTime) }}</td>
            <td>{{ timeDisplay(totals.totalsThisPage.InstructorTime) }}</td>
            <td class="subtotalLabel"></td>
          </tr>

          <tr class="subtotal">
            <td class="subtotalLabel" colspan="6" rowspan="1"></td>
            <td colspan="2" rowspan="1">Totals previous Page</td>

            <td>{{ timeDisplay(totals.totalsPreviousPage.SETime) }}</td>

            <td>{{ timeDisplay(totals.totalsPreviousPage.METime) }}</td>
            <td>{{ timeDisplay(totals.totalsPreviousPage.multiPilotTime) }}</td>
            <td>
              {{ timeDisplay(totals.totalsPreviousPage.totalFlightTime) }}
            </td>
            <td></td>
            <td>{{ totals.totalsPreviousPage.landingsDay }}</td>
            <td>{{ totals.totalsPreviousPage.landingsNight }}</td>
            <td>{{ timeDisplay(totals.totalsPreviousPage.nightTime) }}</td>
            <td>{{ timeDisplay(totals.totalsPreviousPage.IFRTime) }}</td>
            <td>{{ timeDisplay(totals.totalsPreviousPage.PICTime) }}</td>
            <td>{{ timeDisplay(totals.totalsPreviousPage.CopiTime) }}</td>
            <td>{{ timeDisplay(totals.totalsPreviousPage.DualTime) }}</td>
            <td>{{ timeDisplay(totals.totalsPreviousPage.InstructorTime) }}</td>
            <td class="subtotalLabel"></td>
          </tr>

          <tr class="subtotal">
            <td class="subtotalLabel" colspan="6" rowspan="1"></td>
            <td colspan="2" rowspan="1">Total Time</td>

            <td>{{ timeDisplay(totals.totalTime.SETime) }}</td>

            <td>{{ timeDisplay(totals.totalTime.METime) }}</td>
            <td>{{ timeDisplay(totals.totalTime.multiPilotTime) }}</td>
            <td>{{ timeDisplay(totals.totalTime.totalFlightTime) }}</td>
            <td></td>
            <td>{{ totals.totalTime.landingsDay }}</td>
            <td>{{ totals.totalTime.landingsNight }}</td>
            <td>{{ timeDisplay(totals.totalTime.nightTime) }}</td>
            <td>{{ timeDisplay(totals.totalTime.IFRTime) }}</td>
            <td>{{ timeDisplay(totals.totalTime.PICTime) }}</td>
            <td>{{ timeDisplay(totals.totalTime.CopiTime) }}</td>
            <td>{{ timeDisplay(totals.totalTime.DualTime) }}</td>
            <td>{{ timeDisplay(totals.totalTime.InstructorTime) }}</td>
            <td class="subtotalLabel"></td>
          </tr>
        </tbody>
      </table>
    </main>

    <footer v-if="logbook.length > 0">
      <div class="flex flex-col mb-1">
        <div
          class="flex flex-row justify-center justify-self-center m-auto text-center align-middle my-4 select-none"
        >
          <nav>
            <ul class="inline-flex -space-x-px">
              <li>
                <a
                  @click="previousPage"
                  class="cursor-pointer py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >Previous</a
                >
              </li>
              <li :key="n" v-for="n in pageValues">
                <a
                  @click="
                    currentPage = n;
                    changePage();
                  "
                  :class="
                    currentPage == n
                      ? 'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                      : 'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                  "
                  class="py-2 px-3 leading-tight border border-gray-300 cursor-pointer"
                  >{{ n }}</a
                >
              </li>
              <li>
                <a
                  @click="nextPage"
                  class="cursor-pointer py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >Next</a
                >
              </li>
            </ul>
          </nav>
        </div>

        <div class="flex flex-row justify-center gap-5">
          <button
            @click="showSettings = !showSettings"
            type="button"
            class="select-none w-32 h-auto text-center align-middle text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
            Settings
          </button>
          <button
            @click="showImport = true"
            type="button"
            class="select-none w-32 py-2.5 h-auto text-center align-middle text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Import data
          </button>
        </div>
      </div>
    </footer>

    <!-- Import modal -->
    <div
      id="importhelpmodal"
      tabindex="-1"
      :class="showImport ? 'inheret' : 'hidden'"
      class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full shadow-lg"
    >
      <div
        class="p-4 w-full max-w-2xl h-full md:h-auto absolute"
        style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
      >
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div
            class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600"
          >
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              Importing data
            </h3>
            <div></div>
          </div>
          <!-- Modal body -->
          <div class="p-6 space-y-6">
            <div v-if="importPage == 1">
              <img
                src="@/assets/tutorial/logbookbutton.png"
                alt="Logbook Button"
                class="mx-auto"
              />
              <p
                class="text-base leading-relaxed text-gray-500 dark:text-gray-400"
              >
                Go to flightlogger and in the top right corner click on your
                name and select 'Logbook'
              </p>
            </div>
            <div v-if="importPage == 2">
              <img
                src="@/assets/tutorial/dateselect.png"
                alt="Logbook Button"
                class="mx-auto"
              />
              <p
                class="text-base leading-relaxed text-gray-500 dark:text-gray-400"
              >
                In the top left corner select the date. This should be a date
                from which you want to generate the logbook. (E.g. 1 januari
                2000)
              </p>
            </div>
            <div v-if="importPage == 3">
              <img
                src="@/assets/tutorial/findreport.png"
                alt="Logbook Button"
                class="mx-auto"
              />
              <p
                class="text-base leading-relaxed text-gray-500 dark:text-gray-400"
              >
                Make sure to press the 'Find report' button!
              </p>
            </div>
            <div v-if="importPage == 4">
              <img
                src="@/assets/tutorial/savecsv.png"
                alt="Logbook Button"
                class="mx-auto"
              />
              <p
                class="text-base leading-relaxed text-gray-500 dark:text-gray-400"
              >
                Lastly save the logbook as a CSV using the button in the top
                right corner and press 'Export As' and 'CSV (Excel)'. Then
                upload using the button below
              </p>
            </div>

            <nav aria-label="Page navigation example">
              <ul class="inline-flex -space-x-px">
                <!-- <li>
                  <a
                    href="#"
                    class="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >Previous</a
                  >
                </li> -->
                <li>
                  <a
                    @click="importPage = 1"
                    :class="
                      importPage == 1
                        ? 'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                        : 'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                    "
                    class="py-2 px-3 leading-tight border border-gray-300 cursor-pointer"
                    >1</a
                  >
                </li>
                <li>
                  <a
                    @click="importPage = 2"
                    :class="
                      importPage == 2
                        ? 'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                        : 'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                    "
                    class="py-2 px-3 leading-tight border border-gray-300 cursor-pointer"
                    >2</a
                  >
                </li>
                <li>
                  <a
                    @click="importPage = 3"
                    :class="
                      importPage == 3
                        ? 'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                        : 'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                    "
                    class="py-2 px-3 leading-tight border border-gray-300 cursor-pointer"
                    >3</a
                  >
                </li>
                <li>
                  <a
                    @click="importPage = 4"
                    :class="
                      importPage == 4
                        ? 'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                        : 'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                    "
                    class="py-2 px-3 leading-tight border border-gray-300 cursor-pointer"
                    >4</a
                  >
                </li>
                <!-- <li>
                  <a
                    href="#"
                    class="py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >Next</a
                  >
                </li> -->
              </ul>
            </nav>
          </div>

          <div
            class="flex flex-col justify-center text-center m-auto justify-items-center align-middle"
          >
            <div class="flex items-center mb-4 justify-center cursor-pointer">
              <input
                id="applycorrection-checkbox"
                type="checkbox"
                v-model="applyCorrections"
                class="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="applycorrection-checkbox"
                class="ml-2 text-sm cursor-pointer font-medium text-gray-900 dark:text-gray-300"
                >Apply default corrections</label
              >
            </div>
            <div class="justify-center flex text-left m-auto">
              <!-- <input @change="readCSVFile" type="file" id="fileinput" /> -->
              <div class="justify-start flex flex-col">
                <label
                  class="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
                  for="file_input"
                  >Upload file</label
                >
                <!-- <div class="justify-center"> -->
                <input
                  @change="readCSVFile"
                  class="block text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="file_input_help"
                  id="file_input"
                  type="file"
                />
                <!-- </div> -->

                <p
                  class="mt-1 text-right text-sm text-gray-500 dark:text-gray-300"
                  id="file_input_help"
                >
                  CSV
                </p>
              </div>
            </div>
          </div>

          <!-- Modal footer -->
          <div
            class="flex flex-row-reverse items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600"
          >
            <button
              @click="showImport = false"
              v-if="logbook.length > 0"
              type="button"
              class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Settings modal -->
    <div
      id="importhelpmodal"
      tabindex="-1"
      :class="showSettings ? 'inheret' : 'hidden'"
      class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full shadow-lg"
    >
      <div
        class="p-4 w-full max-w-2xl h-full md:h-auto absolute"
        style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
      >
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div
            class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600"
          >
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              Settings
            </h3>
            <div></div>
          </div>
          <!-- Modal body -->
          <div class="p-6 space-y-6">
            <section>
              <label
                for="steps-range"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Logbook offset: {{ settings.offset }}</label
              >
              <input
                @change="changePage"
                id="steps-range"
                v-model="settings.offset"
                type="range"
                min="0"
                max="8"
                step="1"
                class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
              />
            </section>

            <section>
              <div class="grid gri-cols-4 gap-4">

              </div>
            </section>
          </div>

          <!-- Modal footer -->
          <div
            class="flex flex-row-reverse items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600"
          >
            <button
              @click="showSettings = false"
              type="button"
              class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogbookRow from "./LogbookRow.vue";
import LogbookEntry from "../utils/logbookentry";
import { secondsToHM } from "../utils/timeCalculator";

import parseCSV from "../utils/CSVParser";
import CSVArrayParser from "../utils/CSVArrayParser";
import parseFLLogbook from "../utils/parseFLLogbook";

export default {
  components: {
    LogbookRow,
  },
  mounted: () => {
    // function readCSVFile(evt) {
    //   var f = evt.target.files[0];
    //   if (f) {
    //     var r = new FileReader();
    //     r.onload = function (e) {
    //       var contents = e.target.result;
    //       this.logbook = parseFLLogbook(CSVArrayParser(parseCSV(contents)));
    //       console.log(this.logbook);
    //     };
    //     r.readAsText(f);
    //   } else {
    //     alert("Failed to load file");
    //   }
    // }
    // document
    //   .getElementById("fileinput")
    //   .addEventListener("change", readCSVFile);
    // console.log(this.logbook);
  },

  methods: {
    readSingleFile: function (evt) {
      var output = [];
      var f = evt.target.files[0];
      if (f) {
        var r = new FileReader();
        r.onload = function (e) {
          var contents = e.target.result;
          console.log(parseCSV(contents));
          document.write(
            "File Uploaded! <br />" +
              "name: " +
              f.name +
              "<br />" +
              "content: " +
              contents +
              "<br />" +
              "type: " +
              f.type +
              "<br />" +
              "size: " +
              f.size +
              " bytes <br />"
          );
          var lines = contents.split("\n");
          for (var i = 0; i < lines.length; i++) {
            output.push(
              "<tr><td>" + lines[i].split(",").join("</td><td>") + "</td></tr>"
            );
          }
          output = "<table>" + output.join("") + "</table>";
          document.write(output);
        };
        r.readAsText(f);
        document.write(output);
      } else {
        alert("Failed to load file");
      }
    },

    readCSVFile(evt) {
      console.log(this);
      var f = evt.target.files[0];
      if (f) {
        var r = new FileReader();
        r.onload = (e) => {
          // console.log(this);
          // console.warn(this.applyCorrections);
          var contents = e.target.result;
          //   console.log(this.$store, this.$store.state, contents);
          this.logbook = parseFLLogbook(
            CSVArrayParser(parseCSV(contents)),
            this.$store,
            this.applyCorrections
          ).filter((el) => el.depAirport != "");
          console.log(this.logbook);
          this.showImport = false;
          this.changePage();
        };
        r.readAsText(f);
      } else {
        alert("Failed to load file");
      }
    },

    changePage: function () {
      // }
      var logbook = [...this.logbook];
      for (let i = 0; i < this.settings.offset; i++) {
        var entry = new LogbookEntry();
        entry.Id = this.makeid(15);
        logbook.unshift(entry);
      }
      this.currentPageData = logbook.slice(
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * 8,
        (this.currentPage - 1) * 8 + 8
      );
      this.pastPageData = logbook.slice(
        0,
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * 8
      );
      this.totalPageData = this.pastPageData.concat(this.currentPageData);
      this.totals.totalsThisPage = this.calculateTotals(this.currentPageData);
      this.totals.totalsPreviousPage = this.calculateTotals(this.pastPageData);
      this.totals.totalTime = this.calculateTotals(this.totalPageData);
    },

    nextPage: function () {
      // if (this.currentPage <= this.logbook / 8) {
      this.currentPage = this.currentPage + 1;
      // }
      this.changePage();
    },

    previousPage: function () {
      this.currentPage = this.currentPage - 1;
      if (this.currentPage < 1) this.currentPage = 1;
      this.changePage();
    },

    /**
     *
     * @param {logbookEntry[]} logbook
     */
    filteredFlights(logbook) {
      logbook.filter((el) => {
        // console.log(el.depAirport, el.depAirport != "");
        return el.depAirport != "";
      });
    },

    filteredSim(logbook) {
      logbook.filter((el) => {
        return el.depAirport == "";
      });
    },

    calculateTotals(logbook) {
      var entry = new LogbookEntry();

      for (let i = 0; i < logbook.length; i++) {
        const el = logbook[i];
        entry.SETime += el.SETime;
        entry.METime += el.METime;
        entry.multiPilotTime += el.multiPilotTime;
        entry.totalFlightTime += el.totalFlightTime;
        entry.landingsDay += el.landingsDay;
        entry.landingsNight += el.landingsNight;
        entry.nightTime += el.nightTime;
        entry.IFRTime += el.IFRTime;
        entry.PICTime += el.PICTime;
        entry.CopiTime += el.CopiTime;
        entry.DualTime += el.DualTime;
        entry.InstructorTime += el.InstructorTime;
      }

      return entry;
    },

    timeDisplay(d) {
      // console.log(secondsToHM(d));
      return d != 0 ? secondsToHM(d) : "";
    },
    dateDisplay(d) {
      return d < 10 ? "0" + d : d + "";
    },

    makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },

  data() {
    return {
      sampleData: [
        {
          year: "2021",
          month: "09",
          day: "04",
          depAirport: "LSZA",
          depTime: "1704",
          arrAirport: "LSZL",
          arrTime: "1733",
          acModel: "SLG2",
          acRegistration: "HBKGH",
          SETime: "0029",
          METime: "",
          multiPilotTime: "",
          totalFlightTime: "0029",
          namePIC: "E. Totaro",
          landingsDay: "1",
          landingsNight: "",
          nightTime: "",
          IFRTime: "",
          PICTime: "",
          CopiTime: "",
          DualTime: "0029",
          InstructorTime: "",
          Remarks: "E.1.14 Cross country navigation 1",
        },
        {
          year: "2021",
          month: "09",
          day: "04",
          depAirport: "LSZA",
          depTime: "1704",
          arrAirport: "LSZL",
          arrTime: "1733",
          acModel: "SLG2",
          acRegistration: "HBKGH",
          SETime: "0029",
          METime: "",
          multiPilotTime: "",
          totalFlightTime: "0029",
          namePIC: "E. Totaro",
          landingsDay: "1",
          landingsNight: "",
          nightTime: "",
          IFRTime: "",
          PICTime: "",
          CopiTime: "",
          DualTime: "0029",
          InstructorTime: "",
          Remarks: "E.1.14 Cross country navigation 1",
        },
        {
          year: "2021",
          month: "09",
          day: "04",
          depAirport: "LSZA",
          depTime: "1704",
          arrAirport: "LSZL",
          arrTime: "1733",
          acModel: "SLG2",
          acRegistration: "HBKGH",
          SETime: "0029",
          METime: "",
          multiPilotTime: "",
          totalFlightTime: "0029",
          namePIC: "E. Totaro",
          landingsDay: "1",
          landingsNight: "",
          nightTime: "",
          IFRTime: "",
          PICTime: "",
          CopiTime: "",
          DualTime: "0029",
          InstructorTime: "",
          Remarks: "E.1.14 Cross country navigation 1",
        },
        {
          year: "2021",
          month: "09",
          day: "04",
          depAirport: "LSZA",
          depTime: "1704",
          arrAirport: "LSZL",
          arrTime: "1733",
          acModel: "SLG2",
          acRegistration: "HBKGH",
          SETime: "0029",
          METime: "",
          multiPilotTime: "",
          totalFlightTime: "0029",
          namePIC: "E. Totaro",
          landingsDay: "1",
          landingsNight: "",
          nightTime: "",
          IFRTime: "",
          PICTime: "",
          CopiTime: "",
          DualTime: "0029",
          InstructorTime: "",
          Remarks: "E.1.14 Cross country navigation 1",
        },
        {
          year: "2021",
          month: "09",
          day: "04",
          depAirport: "LSZA",
          depTime: "1704",
          arrAirport: "LSZL",
          arrTime: "1733",
          acModel: "SLG2",
          acRegistration: "HBKGH",
          SETime: "0029",
          METime: "",
          multiPilotTime: "",
          totalFlightTime: "0029",
          namePIC: "E. Totaro",
          landingsDay: "1",
          landingsNight: "",
          nightTime: "",
          IFRTime: "",
          PICTime: "",
          CopiTime: "",
          DualTime: "0029",
          InstructorTime: "",
          Remarks: "E.1.14 Cross country navigation 1",
        },
        {
          year: "2021",
          month: "09",
          day: "04",
          depAirport: "LSZA",
          depTime: "1704",
          arrAirport: "LSZL",
          arrTime: "1733",
          acModel: "SLG2",
          acRegistration: "HBKGH",
          SETime: "0029",
          METime: "",
          multiPilotTime: "",
          totalFlightTime: "0029",
          namePIC: "E. Totaro",
          landingsDay: "1",
          landingsNight: "",
          nightTime: "",
          IFRTime: "",
          PICTime: "",
          CopiTime: "",
          DualTime: "0029",
          InstructorTime: "",
          Remarks: "E.1.14 Cross country navigation 1",
        },
        {
          year: "2021",
          month: "09",
          day: "04",
          depAirport: "LSZA",
          depTime: "1704",
          arrAirport: "LSZL",
          arrTime: "1733",
          acModel: "SLG2",
          acRegistration: "HBKGH",
          SETime: "0029",
          METime: "",
          multiPilotTime: "",
          totalFlightTime: "0029",
          namePIC: "E. Totaro",
          landingsDay: "1",
          landingsNight: "",
          nightTime: "",
          IFRTime: "",
          PICTime: "",
          CopiTime: "",
          DualTime: "0029",
          InstructorTime: "",
          Remarks: "E.1.14 Cross country navigation 1",
        },
        {
          year: "2021",
          month: "09",
          day: "04",
          depAirport: "LSZA",
          depTime: "1704",
          arrAirport: "LSZL",
          arrTime: "1733",
          acModel: "SLG2",
          acRegistration: "HBKGH",
          SETime: "0029",
          METime: "",
          multiPilotTime: "",
          totalFlightTime: "0029",
          namePIC: "E. Totaro",
          landingsDay: "1",
          landingsNight: "",
          nightTime: "",
          IFRTime: "",
          PICTime: "",
          CopiTime: "",
          DualTime: "0029",
          InstructorTime: "",
          Remarks: "E.1.14 Cross country navigation 1",
        },
      ],
      showImport: true,
      currentPage: 1,
      importPage: 1,
      logbook: [],
      offset: 0,
      showSettings: false,
      applyCorrections: true,
      currentPageData: [],
      pastPageData: [],
      totalPageData: [],
      settings: {
        offset: 0,
        previousHours: {
          SETime: "",
          METime: "",
          multiPilotTime: "",
          totalFlightTime: "",
          landingsDay: "",
          landingsNight: "",
          nightTime: "",
          IFRTime: "",
          PICTime: "",
          CopiTime: "",
          DualTime: "",
          InstructorTime: "",
        },
      },
      totals: {
        totalsThisPage: new LogbookEntry(),
        totalsPreviousPage: new LogbookEntry(),
        totalTime: new LogbookEntry(),
      },
    };
  },

  computed: {
    pageValues: function () {
      var valuesOnPage = 8;
      var value = [];
      // console.log(Math.ceil(this.logbook.length / 8));
      if (Math.ceil(this.logbook.length / valuesOnPage) <= 10) {
        for (let i = 0; i < Math.ceil(this.logbook.length / 8); i++) {
          value.push(i + 1);
        }
      } else {
        var pages = Math.ceil(this.logbook.length / valuesOnPage);
        var currentPage = this.currentPage;
        if (currentPage <= 5) {
          for (let j = 0; j < 10; j++) {
            value.push(j + 1);
          }
        } else if (currentPage < pages - 5) {
          for (let k = currentPage - 5; k < currentPage + 4; k++) {
            value.push(k + 1);
          }
        } else {
          for (let l = pages - 10; l < pages; l++) {
            value.push(l + 1);
          }
        }
      }

      return value;
    },
  },

  watch: {
    // logbook: {
    //   deep: true,
    //   handler(logbook) {
    //     this.totals.totalsThisPage = this.calculateTotals(logbook);
    //     this.totals.totalsPreviousPage = this.calculateTotals(logbook);
    //     this.totals.totalTime = this.calculateTotals(logbook);
    //   },
    // },
  },
};
</script>

<style scoped lang="scss">
tr.bordered {
  page-break-inside: avoid;
}

tr.bordered td,
tr.bordered th {
  padding: 3px;
  border: 1px solid black;
  page-break-inside: avoid;
  font-weight: normal;
}

tr.bordered td {
  font-size: smaller;
}

tr.borderedBold th {
  padding: 3px;
  border: 1px solid black;
  page-break-inside: avoid;
  font-weight: bold;
  text-align: center;
}

tr.subtotal td {
  padding: 2px;
  border: 1px solid gray;
  page-break-inside: avoid;
  font-weight: bold;
}

tr.subtotal td.subtotalLabel {
  border: none;
}

.headerBig {
  text-align: center;
  font-weight: bold;
}
.headerSmall {
  text-align: center;
  font-size: smaller;
}
</style>
