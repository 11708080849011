<template>
  <div id="app">
    <!-- <header class="sticky top-0 z-50 w-full" v-if="$route.meta.showNavbar">
      <navbar class="relative" id="nav" />
    </header> -->
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
// import navbar from "./components/Navbar.vue";
// import { getAuth, getRedirectResult, OAuthProvider } from "firebase/auth";
export default {
  components: {
    // navbar,
  },

  mounted() {
    // getRedirectResult(getAuth(this.$fApp))
    //   .then((result) => {
    //     const credential = OAuthProvider.credentialFromResult(result);
    //     if (credential) {
    //       // You can also get the Apple OAuth Access and ID Tokens.
    //       const _accessToken = credential.accessToken;
    //       const _idToken = credential.idToken;
    //       console.log(_accessToken, _idToken);
    //     }
    //     // The signed-in user info.
    //     const user = result.user;
    //     console.log(user);
    //     this.$store.commit("setUser", user);
    //     this.$router.push("/");
    //   })
    //   .catch((error) => {
    //     // Handle Errors here.
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     // The email of the user's account used.
    //     // const email = error.customData.email;
    //     // The credential that was used.
    //     const credential = OAuthProvider.credentialFromError(error);
    //     // ...
    //     console.error(errorMessage, errorCode, credential);
    //   });
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
