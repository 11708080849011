<template>
  <!-- <div class="home"> -->
  <logbook-table></logbook-table>
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import LogbookTable from "../components/LogbookTable.vue";

export default {
  name: "Logbook",
  components: {
    LogbookTable,
  },
};
</script>
