export default class LogbookEntry {
  /** @type {Date}} */
  date;
  /** @type {String} */
  depAirport;
  /** @type {Date} */
  depTime;
  /** @type {String} */
  arrAirport;
  /** @type {Date} */
  arrTime;
  /** @type {String} */
  acModel;
  /** @type {String} */
  acRegistration;
  /** @type {Number} */
  SETime = 0;
  /** @type {Number} */
  METime = 0;
  /** @type {Number} */
  multiPilotTime = 0;
  /** @type {Number} */
  totalFlightTime = 0;
  /** @type {Number} */
  namePIC;
  /** @type {String} */
  landingsDay = 0;
  /** @type {Number} */
  landingsNight = 0;
  /** @type {Number} */
  nightTime = 0;
  /** @type {Number} */
  IFRTime = 0;
  /** @type {Number} */
  PICTime = 0;
  /** @type {Number} */
  CopiTime = 0;
  /** @type {Number} */
  DualTime = 0;
  /** @type {Number} */
  InstructorTime = 0;
  /** @type {String} */
  Remarks;
  /** @type {String} */
  Id;

  // /**
  //  *
  //  * @param {Date} date
  //  * @param {String} depAirport
  //  * @param {Date} depTime
  //  * @param {String} arrAirport
  //  * @param {Date} arrTime
  //  * @param {String} acModel
  //  * @param {String} acRegistration
  //  * @param {Number} SETime
  //  * @param {Number} METime
  //  * @param {Number} multiPilotTime
  //  * @param {Number} totalFlightTime
  //  * @param {String} namePIC
  //  * @param {Number} landingsDay
  //  * @param {Number} landingsNight
  //  * @param {Number} nightTime
  //  * @param {Number} IFRTime
  //  * @param {Number} PICTime
  //  * @param {Number} CopiTime
  //  * @param {Number} DualTime
  //  * @param {Number} InstructorTime
  //  * @param {String} Remarks
  //  */
  // constructor(
  //   date,
  //   depAirport,
  //   depTime,
  //   arrAirport,
  //   arrTime,
  //   acModel,
  //   acRegistration,
  //   SETime,
  //   METime,
  //   multiPilotTime,
  //   totalFlightTime,
  //   namePIC,
  //   landingsDay,
  //   landingsNight,
  //   nightTime,
  //   IFRTime,
  //   PICTime,
  //   CopiTime,
  //   DualTime,
  //   InstructorTime,
  //   Remarks
  // ) {
  //   this.date = date;
  //   this.depAirport = depAirport;
  //   this.depTime = depTime;
  //   this.arrAirport = arrAirport;
  //   this.arrTime = arrTime;
  //   this.acModel = acModel;
  //   this.acRegistration = acRegistration;
  //   this.SETime = SETime;
  //   this.METime = METime;
  //   this.multiPilotTime = multiPilotTime;
  //   this.totalFlightTime = totalFlightTime;
  //   this.namePIC = namePIC;
  //   this.landingsDay = landingsDay;
  //   this.landingsNight = landingsNight;
  //   this.nightTime = nightTime;
  //   this.IFRTime = IFRTime;
  //   this.PICTime = PICTime;
  //   this.CopiTime = CopiTime;
  //   this.DualTime = DualTime;
  //   this.InstructorTime = InstructorTime;
  //   this.Remarks = Remarks;
  // }
}
