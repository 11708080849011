<template>
  <div class="home mt-5 mx-3 h-full">
    <logbook-table></logbook-table>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import LogbookTable from "../components/LogbookTable.vue";

export default {
  name: "Home",
  components: {
    LogbookTable,
  },
};
</script>
