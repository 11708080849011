/**
 * Converts seconds to the number shown on the logbook - hhmm
 * @param {Number} seconds the number of seconds to convert
 */
export function secondsToHM(seconds) {
  var h = Math.floor(seconds / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var hDisplay = h < 10 ? "0" + h : h + "";
  var mDisplay = m < 10 ? "0" + m : m + "";
  // console.log(h, m, hDisplay, mDisplay);
  return hDisplay + ":" + mDisplay;
}

/**
 * Converts hh:mm:ss - hh:mm - hhmmss - hhmm to seconds
 * @param {String} d the time string
 * @returns {Number} amount of seconds
 */
export function hmsToSeconds(d) {
  // Test for format 00:00:00 -- hh:mm:ss
  if (
    /([0-9]{2}:[0-9]{2}:[0-9]{2})/.test(d) ||
    /([0-9]{1}:[0-9]{2}:[0-9]{2})/.test(d)
  ) {
    var splitted = d.split(":");
    var hours = splitted[0];
    var minutes = splitted[1];
    var seconds = splitted[2];
    return hours * 3600 + minutes * 60 + seconds;
  }

  // Test for format 00:00 - hh:mm
  else if (/([0-9]{2}:[0-9]{2})/.test(d) || /([0-9]{1}:[0-9]{2})/.test(d)) {
    var splitted2 = d.split(":");
    var hours2 = splitted2[0];
    var minutes2 = splitted2[1];
    return hours2 * 3600 + minutes2 * 60;
  }

  // Test for format 000000 - hhmmss
  else if (/([0-9]{6})/.test(d)) {
    var hours3 = d.substring(0, 2);
    var minutes3 = d.substring(2, 4);
    var seconds3 = d.substring(4, 6);
    return hours3 * 3600 + minutes3 * 60 + seconds3;
  }

  // Test for format 0000 - hhmm
  else if (/([0-9]{4})/.test(d)) {
    var hours4 = d.substring(0, 2);
    var minutes4 = d.substring(2, 4);
    return hours4 * 3600 + minutes4 * 60;
  }

  // Default case
  else {
    return 0;
  }
}
