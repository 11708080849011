import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import LoginPage from "../views/LoginPage.vue";
// import RegisterPage from "../views/RegisterPage.vue";
import Dataview from "../views/Dataview.vue";
import LogbookView from "../views/LogbookView.vue";
import ImportView from "../views/ImportPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      showNavbar: true,
      requireAuth: true,
    },
  },
  {
    path: "/data",
    name: "Data",
    component: Dataview,
    meta: {
      showNavbar: true,
      requireAuth: true,
    },
  },
  {
    path: "/logbook",
    name: "Logbook",
    component: LogbookView,
    meta: {
      showNavbar: true,
      requireAuth: true,
    },
  },
  {
    path: "/import",
    name: "Import",
    component: ImportView,
    meta: {
      showNavbar: true,
      requireAuth: true,
    },
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: LoginPage,
  //   meta: {
  //     showNavbar: false,
  //     requireAuth: false,
  //   },
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: RegisterPage,
  //   meta: {
  //     showNavbar: false,
  //     requireAuth: false,
  //   },
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
