<template>
  <div>
    <label
      for="sources"
      class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
      >Select an import source</label
    >
    <select
      v-model="sourceSelect"
      id="sources"
      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    >
      <option selected>Choose a source</option>
      <option value="FL">Flightlogger</option>
    </select>

    <input @change="readCSVFile" type="file" id="fileinput" accept=".csv" />
  </div>
</template>

<script>
import parseCSV from "../utils/CSVParser";
import CSVArrayParser from "../utils/CSVArrayParser";
// import parseFLLogbook from "../utils/parseFLLogbook";

export default {
  data() {
    return {
      sourceSelect: "",
    };
  },

  methods: {
    readSingleFile: function (evt) {
      var output = [];
      var f = evt.target.files[0];
      if (f) {
        var r = new FileReader();
        r.onload = function (e) {
          var contents = e.target.result;
          console.log(parseCSV(contents));
          document.write(
            "File Uploaded! <br />" +
              "name: " +
              f.name +
              "<br />" +
              "content: " +
              contents +
              "<br />" +
              "type: " +
              f.type +
              "<br />" +
              "size: " +
              f.size +
              " bytes <br />"
          );

          var lines = contents.split("\n");
          for (var i = 0; i < lines.length; i++) {
            output.push(
              "<tr><td>" + lines[i].split(",").join("</td><td>") + "</td></tr>"
            );
          }
          output = "<table>" + output.join("") + "</table>";
          document.write(output);
        };
        r.readAsText(f);
        document.write(output);
      } else {
        alert("Failed to load file");
      }
    },

    readCSVFile(evt) {
      // console.log(this);
      var f = evt.target.files[0];
      if (f) {
        var r = new FileReader();
        r.onload = (e) => {
          console.log(this);
          var contents = e.target.result;
          //   console.log(this.$store, this.$store.state, contents);
          const csvValues = CSVArrayParser(parseCSV(contents));
          console.log(csvValues);

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          var urlencoded = new URLSearchParams();
          urlencoded.append("entries", JSON.stringify(csvValues));
          urlencoded.append("type", this.sourceSelect);
          urlencoded.append("uid", this.$store.state.user.uid);

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow",
          };

          fetch("http://localhost:5090/api/import/flightlogger", requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));
          // this.logbook = parseFLLogbook(
          //   CSVArrayParser(parseCSV(contents)),
          //   this.$store
          // ).filter((el) => el.depAirport != "");
          // console.log(this.logbook);
          // this.currentPageData = this.logbook.slice(
          //   this.currentPage == 1 ? 0 : (this.currentPage - 1) * 8,
          //   (this.currentPage - 1) * 8 + 8
          // );
          // this.pastPageData = this.logbook.slice(
          //   0,
          //   this.currentPage == 1 ? 0 : (this.currentPage - 1) * 8
          // );
          // this.totalPageData = this.pastPageData.concat(this.currentPageData);
          // this.totals.totalsThisPage = this.calculateTotals(
          //   this.currentPageData
          // );
          // this.totals.totalsPreviousPage = this.calculateTotals(
          //   this.pastPageData
          // );
          // this.totals.totalTime = this.calculateTotals(this.totalPageData);
        };
        r.readAsText(f);
      } else {
        alert("Failed to load file");
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
