import moment from "moment";
// import { Store } from "vuex";
import LogbookEntry from "./logbookentry";
import { hmsToSeconds } from "./timeCalculator";

/**
 *
 * @param {Object} data
 * @param {Store} dataStore
 * @param {Boolean} applyCorrections apply the default corrections when true
 * @returns
 */
export default function parseFLLogbook(data, dataStore, applyCorrections) {
  console.log(dataStore);
  var logbook = [];

  for (let i = 0; i < data.length; i++) {
    var entry = new LogbookEntry();

    const e = data[i];

    if (e.date == "") continue;
    if (e.type_of_aircraft == "FNPT II (DA42 MEP)") continue;
    entry.date = moment(e.date, "DD.MM.YYYY");
    entry.depAirport = e.departure_airport_name;
    entry.depTime = hmsToSeconds(e.off_block);
    entry.arrAirport = e.arrival_airport_name;
    entry.arrTime = hmsToSeconds(e.on_block);
    entry.acModel = e.type_of_aircraft;
    entry.acRegistration = e.registration;
    entry.SETime = 0;
    entry.METime = 0;
    entry.IFRTime = 0;
    if (e.single_engine_ifr != "") {
      entry.SETime += hmsToSeconds(e.single_engine_ifr);
      entry.IFRTime += hmsToSeconds(e.single_engine_ifr);
    }
    if (e.single_engine_vfr != "") {
      entry.SETime += hmsToSeconds(e.single_engine_vfr);
    }
    if (e.multi_engine_ifr != "") {
      entry.METime += hmsToSeconds(e.multi_engine_ifr);
      entry.IFRTime += hmsToSeconds(e.multi_engine_ifr);
    }
    if (e.multi_engine_vfr != "") {
      entry.METime += hmsToSeconds(e.multi_engine_vfr);
    }
    entry.multiPilotTime = hmsToSeconds(e.multi_pilot);
    entry.totalFlightTime = hmsToSeconds(e.total);
    entry.namePIC = e.name_of_pilot_in_command;
    entry.landingsDay = Number(e.landings_day);
    entry.landingsNight = Number(e.landings_night);
    entry.nightTime = hmsToSeconds(e.night);
    entry.PICTime = hmsToSeconds(e.pilot_in_command_time);
    entry.CopiTime = hmsToSeconds(e.co_pilot);
    entry.DualTime = hmsToSeconds(e.dual);
    entry.InstructorTime = hmsToSeconds(e.flight_instructor);
    entry.Remarks = e.remarks_and_endorsements;
    entry.Id = e.remarks_and_endorsements + entry.depTime + entry.arrTime;
    // console.log(e, entry);

    if (applyCorrections) {
      // console.log(entry.acModel);
      if (entry.acModel == "S201") entry.acModel = "SLG2";
    }

    logbook.push(entry);
    // dataStore.commit("addEntry", entry);
  }

  return logbook;
}
