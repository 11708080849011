import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    count: 0,
    logbook: [],
    /**
     * @param {import("firebase/auth").User} user the user
     */
    user: null,
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    addEntry(state, entry) {
      state.logbook.push(entry);
    },
    /**
     *
     * @param {*} state
     * @param {import("firebase/auth").User} user
     */
    setUser(state, user) {
      state.user = user;
    },
  },
});
