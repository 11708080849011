import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./index.css";
import "flowbite";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCTdsGVyVHFdciuROYlgOUoUudXVl_5xBk",
  authDomain: "logbook-generator-bram.firebaseapp.com",
  projectId: "logbook-generator-bram",
  storageBucket: "logbook-generator-bram.appspot.com",
  messagingSenderId: "556478034145",
  appId: "1:556478034145:web:5724a026b40dd6fe5f87a0",
  measurementId: "G-Y357H0SE45",
};

// Initialize Firebase
const Fapp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(Fapp);

// const auth = getAuth(Fapp);
// onAuthStateChanged(auth, (user) => {
//   if (user) {
//     // User is signed in, see docs for a list of available properties
//     // https://firebase.google.com/docs/reference/js/firebase.User
//     store.commit("setUser", user);
//     if (!router.currentRoute.meta.requireAuth) {
//       router.push("/");
//     }
//     // ...
//   } else {
//     // User is signed out
//     // ...
//     store.commit("setUser", null);
//     if (router.currentRoute.meta.requireAuth) {
//       router.push("/login");
//     }
//   }
// });

// eslint-disable-next-line no-unused-vars
// router.beforeEach((to, from, next) => {
//   // ...
//   // explicitly return false to cancel the navigation
//   if (store.state.user == null && to.meta.requireAuth) {
//     // return false;
//     next("/login");
//   }

//   next();
// });

Vue.prototype.$fApp = Fapp;

Vue.config.productionTip = false;

// eslint-disable-next-line no-unused-vars
// router.beforeEach(async (to, from) => {
//   if (
//     // make sure the user is authenticated
//     store.state.user != null &&
//     // ❗️ Avoid an infinite redirect
//     to.name !== "Login"
//   ) {
//     // redirect the user to the login page
//     return { name: "Login" };
//   }
//   return true;
// });

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
